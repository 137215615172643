body {
  font-family: "Roboto" !important;
  overflow-x: hidden;
  background-color: #f7f7f9 !important;
}

* {
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}


.flow-part {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.center img {
  width: 15px;
  margin: 0 7px 3px;
}

.center p {
  margin-left: 40px;
  font-weight: bolder;
}

/* login page start */

.login {
  background: transparent linear-gradient(119deg, #ff7f03 0%, #bc5c00 100%) 0% 0% no-repeat padding-box;
  height: 100vh;
}

.login .form-section {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 8px;
  border: none;
  position: relative;
  top: 17%;
  box-shadow: 0px 3px 6px #00000029;
}

.login .form-section .logo-part {
  background-color: #ff7f03;
  padding-top: 190px;
  padding-bottom: 110px;
  border-radius: 8px 0px 0px 8px;
}

.login .form-section .logo-part img {
  position: relative;
  top: 39%;
}

.login .form-section .logo-part h2 {
  text-align: center;
  position: relative;
  top: 41%;
  padding-top: 5px;
  color: #fff;
  font-size: 28px;
  font-family: "Roboto";
}

.login .form-section .form-part {
  padding-right: 70px;
  padding-left: 40px;
  padding-top: 100px;
  padding-bottom: 30px;
}

.login .form-section .form-part h3 {
  text-transform: uppercase;
  font-size: 20px;
  color: #121212;
  font-weight: 600;
}

.login .form-section .form-part p {
  font-size: 13px;
  color: #aaaaaa;
}

.form-part .form-control:focus {
  border-color: #ff7f03 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #ff7f03 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control {
  border: 1.6px solid #c4c4c480 !important;
  font-size: 13.5px !important;
  height: 40px !important;
}

.clone_select {
  margin: -40px 18px 0 20px;
}

.clone_select .form-control {
  margin: -20px 0 15px;
}

.clone_select label {
  position: relative;
  bottom: -20px;

}

.modal-staff .form-select {
  border: 1.6px solid #c4c4c480 !important;
  font-size: 13.5px !important;
}

::placeholder {
  font-size: 12px;
  font-family: "Roboto";
  color: #aaaaaa !important;
}

.form-part .form-control {
  margin-top: 25px;
  margin-bottom: 10px;
}

.form-part button {
  width: 100%;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 510;
  letter-spacing: 0.4px;
}

.form-part button:hover {
  box-shadow: 0px 6px 6px #00000029;
}

.form-part a p {
  font-size: 12px;
  color: #ff7f03 !important;
  float: right;
  text-decoration: underline;
  font-weight: 500;
}


.form-part a p:hover {
  transition-duration: 1s;
  letter-spacing: 0.5px;
}

.form-part button a {
  color: #fff;
  float: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 510;
  letter-spacing: 0.4px;
}

.form-part i {
  position: absolute;
  left: 13px;
  top: 21.5%;
}

.form-part a i {
  color: #121212;
}

.Reset {
  padding-top: 80px !important;
}

.Reset i {
  position: absolute;
  left: 13px;
  top: 17.5%;
}

.verify {
  padding-top: 30px !important;
}

.verify i {
  position: absolute;
  left: 13px;
  top: 7%;
}

.verify .form-control {
  margin-top: 10px !important;
}



.form-check-box {
  position: relative;
  top: 0px;
  left: 63px;
}


.form-label {
  font-size: 13px;
  font-weight: 500;
}

.verify .change-password {
  background-color: #1212120f;
  color: #ff7f03;
  margin-top: 20px;
}

.verify .change-password img {
  position: relative;
  left: 10px;
  top: 3px;
}

.verify .change-password p {
  color: #ff7f03 !important;
  position: relative;
  top: 8px;
  float: none;
  font-size: 12px;
}

.verify .change-password i {
  color: #ff7f03 !important;
  position: relative;
  top: 5px;
  font-size: 10px;
  left: -20px;
}

.login .form-section .verify-part {
  background-color: #ff7f03;
  padding-bottom: 134px !important;
  border-radius: 8px 0px 0px 8px;
  padding: 20px;
}

.login .form-section .verify-part p {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  top: 30px;
  left: 90px;
}

.verify-part .profile-img {
  margin-top: 70px;
}

.profile h4 {
  /* margin-left: -40px !important; */
  margin: 8px 0px 0px 8px !important;
}

.choose {
  padding: 7px 20px;
  background: transparent;
  margin-top: 20px;
  border: 1px solid #fff;
  display: table;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.chooses {
  padding: 7px 20px;
  background: transparent;
  margin-top: 20px;
  border: 1px solid #ff7f03;
  display: table;
  color: #ff7f03;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.mobile-back {
  color: #fff !important;
  text-align: center;
}

.read td a {
  color: #000;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .spin {
    width: 30%;
  }

  .side-bar {
    display: none !important;
  }

  .login .form-section {
    position: relative;
    top: 0;
    border-radius: 0px;
  }

  .login .form-section .logo-part {
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 0px;
  }

  .login .form-section .form-part {
    padding: 50px;
  }

  .Reset {
    padding-top: 50px !important;
  }

  .login .form-section .verify-part {
    border-radius: 0px;
  }

  .login .form-section .verify-part img {
    width: 20%;
  }

  .login .form-section .verify-part p {
    margin-left: 30px;
    font-size: 20px;
  }

  .login i {
    display: none;
  }
}

/* login page End */

/* Navbar Start */

.nav-top {
  padding-bottom: 10px !important;
  padding: 20px;
  padding-left: 17px;
  /* padding-left: 30px; */
  background-color: #f7f7f9;
}

.nav-top a {
  color: #121212;
}

.nav-top a:hover {
  color: #121212;
}

.nav-top img {
  float: left;
}

.nav-top span img {
  position: absolute;
  top: 38%;
  left: 4%;
}

.admin {
  position: absolute;
  left: 37%;
  top: 13px;
  font-size: 22px;
  font-weight: 800;
  font-family: "Roboto" !important;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

::-webkit-input-placeholder {
  font-size: 12px !important;
}

:-ms-input-placeholder {
  font-size: 12px !important;
}

::placeholder {
  font-size: 12px !important;
}

.nav-top .bell {
  padding-top: 17px;
  position: absolute;
  right: 10px;
}

.nav-top .notification-profile {
  cursor: pointer;
}

.nav-top .notification-profile .profile {
  padding-top: 6px;
}

.profiles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.nav-top .notification-profile h6 {
  font-size: 13px;
  line-height: 10px;
  /* padding-left: 50px !important; */
  padding-top: 10px;
}

.nav-top .notification-profile p {
  font-size: 11px;
  /* padding-left: 50px !important; */
  line-height: 5px;
  color: #aaaaaa;
}

/* Navbar End */

/* Sidebar Start */
.dashboard-page {
  width: 100%;
  display: flex;
}

.dashboard-sidebar {
  width: 6%;

}

.dashboard-main {
  /* border: 2px solid red; */
  width: 94%;
  padding-right: 20px;
}

.side-bar {
  width: 100%;
  height: 80vh;
  border: none !important;
  background-color: #f7f7f9 !important;
  padding-top: 0px;
  border-radius: 5px !important;
}

.side-bar a {
  color: #000;
  text-decoration: none !important;
  font-weight: 430;
}

.side-bar a li {
  color: #000;
  padding: 10px 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar a li img {
  margin-left: 6px;
  width: 18px;
  height: 18px;
}

.side-bar .active li {
  background-color: #ff7f03 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .active img {
  filter: brightness(0) invert(1) !important;
}

.dashboard-page {
  background-color: #f7f7f9;
  padding-top: 10px;
  padding-bottom: 20px;
}

.side-bar a li:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none !important;
}

.footer li {
  background-color: #e3e7ef !important;
}

.footer li:hover {
  background-color: #fff !important;
  color: #000;
  text-decoration: none !important;
}

.active .notification-no {
  background-color: #fff;
  color: #ff7f03;
}

.notification-no {
  background-color: #ff7f03;
  position: absolute;
  font-size: 9px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 5px;
  border-radius: 100%;
  right: 35px;
  margin-top: 3px;
  color: #fff;
}

/* Sidebar End */

/* scrollbar start*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bc5c00;
  border-radius: 10px;
}

/* scrollbar End*/

/*dashboard card & table start */

.background-image {
  background-image: url(./assets/images/orange-back.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
}

.background-image a {
  color: #000;
}

.dashboard-page .background-image h5 {
  color: #fff;
  font-size: 16px;
}

.dashboard-page .background-image p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .background-image .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.green-back {
  background-image: url(./assets/images/green-back.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
}

.green-back a {
  color: #000;
}

.dashboard-page .green-back h5 {
  color: #fff;
  font-size: 16px;
}

.dashboard-page .green-back p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .green-back .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.blue-back {
  background-image: url(./assets/images/blue-back.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
}

.blue-back a {
  color: #000;
}

.dashboard-page .blue-back h5 {
  color: #fff;
  font-size: 16px;
}

.dashboard-page .blue-back p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .blue-back .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.red-back {
  background-image: url(./assets/images/red-back.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
  margin-top: 30px;
}

.red-back a {
  color: #000;
}

.light-blue_back {
  background-image: url(./assets/images/blue_logo.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
  margin-top: 30px;
}

.light-blue_back a {
  color: #000;
}


.dashboard-page .light-blue_back .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.dashboard-page .light-blue_back h5 {
  color: #fff;
  font-size: 16px;
}


.dashboard-page .light-blue_back p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .red-back h5 {
  color: #fff;
  font-size: 16px;
}

.dashboard-page .red-back p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .red-back .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.black-back {
  background-image: url(./assets/images/black-back.svg);
  background-size: cover;
  width: 101%;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 9px;
  border: none !important;
  box-shadow: 0px 1px 1px #0000001a;
  margin-top: 30px;
}

.dashboard-tag .card {
  border-radius: 9px;
}

.black-back a {
  color: #000;
}

.dashboard-page .black-back h5 {
  color: #fff;
  font-size: 16px;
}

.dashboard-page .black-back p {
  color: #fff;
  font-size: 10px;
}

.dashboard-page .black-back .dashboard-btn {
  width: 100%;
  border: none;
  padding: 7px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 3px;
}

.dashboard-page .dashboard-card h6 {
  padding-bottom: 5px;
  padding-top: 2px;
}

.dashboard-page .dashboard-card {
  background-color: #fff !important;
  padding: 30px;
  padding-top: 23px;
  padding-bottom: 25px;
  border-radius: 7px;
  box-shadow: 0px 1px 1px #0000001a;
  width: 101%;
  border: none;
}

.dashboard-page .dashboard-card img {
  width: 35px;
}

.dashboard-page .dashboard-card .line {
  border-left: 1px solid #dad8d8;
}

.line img {
  float: right;
}

.dashboard-page .dashboard-card p {
  font-size: 14px;
  margin-top: 5px;
}

.dashboard-page .dashboard-card h5 {
  font-weight: 900;
  color: #121212;
  margin-top: -5px;
}

.dashboard-page .quotation-part {
  background-color: #fff;
  margin-top: 20px;
  /* width: 101%; */
  border: none;
  box-shadow: 0px 1px 1px #0000001a;
  padding: 20px;
}

.dashboard-page .quotation-part p {
  position: absolute;
  right: 40px;
  top: 16px;
  color: #ff7f03;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.dashboard-page .quotation-part i {
  position: absolute;
  right: 20px;
  top: 18px;
  color: #ff7f03;
  font-size: 12px;
  cursor: pointer;
}

.generate_spin {
  padding-top: 40px;
}

.draft_spin {
  padding-top: 25px;
}

.quotation-parts h4 {
  position: absolute;
  right: 45px;
  font-weight: 400 !important;
  top: 30px;
  color: #ff7f03;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.quotation-parts i {
  position: absolute;
  right: 25px;
  top: 30px;
  color: #ff7f03;
  font-size: 12px;
  cursor: pointer;
}

.dashboard-page .quotation-part .table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table thead tr th {
  background-color: #f8f8f8;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 401 !important;
  font-size: 14px;
  color: #aaaaaa;
  position: sticky;
  top: 0px;
  white-space: nowrap;
  z-index: 99;
}

.size-height td,
.size-height th {
  padding: 10px !important;
}

.space-part {
  margin: 20px;
  overflow-x: hidden !important;
}

.space-parts {
  margin: 20px;
}

.product-svg img {
  width: 60px;
}

.product-svg {
  background-color: rgb(247, 245, 245);
  padding: 50px;
  border-radius: 50%;
  margin-top: 10px;
}

#popover-contained {
  background-color: #fff !important;
  z-index: 99;
  padding: 10px 13px;
}

#popover-contained label {
  margin-top: 5px;
}

.table tbody tr td {
  font-size: 14px;
  color: #121212;
  white-space: nowrap;
  min-width: 40px;
}


.dashboard-page .quotation-part .table-class {
  margin-top: 10px;
}

.table-class {
  overflow-y: auto;
  overflow-x: auto;
  height: 350px;
}

.table-classs {
  overflow-y: auto;
  overflow-x: auto;
  /* height: 350px; */
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}

.new-table {
  height: 100px !important;
}

/*dashboard card & table End */

/* Key frame Animation */
.spin {
  animation-name: spin;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.shake-text {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.bell:hover,
.bell:focus {
  animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  transform-origin: top right;
  cursor: pointer;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* Key frame End */

/* staff page start */

.dashboard-page .staff-page .head-img {
  width: 34px;
  padding: 7px;
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 4px;
  width: 32px;
  height: 32px;
}

.po_link {
  text-decoration: underline;
  cursor: pointer;
}

.ignore_field_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
  margin-right: -10px;
  border-radius: 4px;
}

.blue_body {
  background-color: #D9EAFB !important;
  padding: 0px 0 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project_body .new {
  padding: 11px 90px;
  margin-left: -1px;
}

.table-blue thead tr th {
  text-align: center;
}

.po-header {
  margin: 0px 5px 0 20px;
  /* padding-left: 30px;
  padding-right: 30px; */
}

.table-blue tr td {
  border-left: 1.4px solid #AAAAAA7F;
  border-bottom: 1.4px solid #AAAAAA7F;
  border-right: 1.4px solid #AAAAAA7F;
}

.table-blue tr th {
  border-right: 1.4px solid #AAAAAA7F;
  border-bottom: 1.4px solid #177DE2;
}

.table-blue tr th:last-child {
  border: none;
}

.po_form {
  margin: 0 13px;
}

.po-header h4 {
  font-size: 15px;
  margin-left: 8px;
  margin-top: 5px;
}

.po-header .col-md-6 h4 {
  margin-top: 8px;
  margin-right: -20px;
  text-align: right;
}

.dashboard-page .staff-page .head-imgs {
  width: 30px;
  padding: 7px;
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 4px;
  width: 32px;
  height: 32px;
}

.dashboard-page .staff-page {
  padding: 20px;
  border: none;
  border-radius: 5px;
  min-height: 350px;
}

.dashboard-page .staff-page .table-class {
  height: 520px;
  margin-top: 20px;
}

.dashboard-page .staff-page h5 {
  position: absolute;
  left: 5%;
  margin-top: 5px;
  font-size: 16px;
}

.dashboard-page .staff-page i {
  position: absolute;
  left: 14%;
  font-size: 12px;
  margin-top: 15px;
}

.header .crt {
  margin-top: 10px !important;
  margin-left: -15px !important;
}

.crt {
  margin-top: 10px !important;
  margin-left: -15px !important;
}

.dashboard-page .staff-page h6 {
  position: absolute;
  left: 16%;
  margin-top: 5px;
  font-size: 16px;
}

.table-class .table thead tr th img {
  width: 16px;
  padding-left: 5px;
  white-space: nowrap;
}

.table-class .table thead tr th img:hover {
  filter: invert(55%) sepia(25%) saturate(6283%) hue-rotate(358deg) brightness(108%) contrast(105%);
  cursor: pointer;
}

.staff-page .table-class .table tbody tr td {
  color: #aaaaaa;
  font-weight: 400;
  white-space: nowrap;
}



.staff-page .table-class .table tbody tr td:hover {
  color: #ff7f03;
  transition-duration: 0.3s;
}

.add-staff {
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  margin-top: -5px;
  padding: 6px 20px;
  position: absolute;
  white-space: nowrap;
  right: 20px;
}

.project-create {
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px 20px;
  margin-top: 20px;

}

.button-center {
  text-align: center;
}

.add-staff {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #bc5c00;
}

.add-staff:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.create-staff {
  width: 150px;
  margin-top: -30px !important;
}

.add-staff img {
  width: 20px;
  padding-right: 6px;
}

.add-product {
  width: 120px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px;
  position: absolute;
  right: 20px;
}

.add-product {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 3px #999;
}

.add-product:active {
  background-color: #000;
  box-shadow: 0 3px rgb(132, 131, 131);
  transform: translateY(2px);
}

.add-product img {
  width: 20px;
  padding-right: 6px;
}

.Edit-staff {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #ff7f03;
  font-weight: 450;
  text-decoration: underline;
}

.Edit-staffs {
  width: 25px;
  cursor: pointer;
}

.quot_body {
  margin-top: -10px;
  margin-right: 3px;
  cursor: pointer;
}

.delete-staff {
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
  z-index: -99;
}

/* staff page End */

/*Pagination start */

.footer-pagination p {
  font-size: 12px;
  color: #aaaaaa;
  margin-top: 20px;
  font-weight: 300;
}

.footer-pagination {
  margin-bottom: -30px;
}

.pagination {
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  font-size: 12px;
}

.first {
  position: absolute;
  left: 20px;
}

.first a {
  border-radius: 30px !important;
  font-size: 12px;
  border: 0px solid #7070701a !important;
  color: #aaaaaa;
  box-shadow: 0px 1px 1px #0000001a;
}

.second {
  position: absolute;
  right: 20px;
}

.second a {
  border-radius: 30px !important;
  font-size: 12px;
  border: 0px solid #afaeae1a !important;
  color: #aaaaaa;
  box-shadow: 0px 1px 1px #0000001a;
}

.page-no a {
  border-radius: 50%;
  margin-left: 15px;
  float: right;
  border: 0px solid #7070701a !important;
  color: #aaaaaa;
  position: relative;
  left: 250%;
  box-shadow: 0px 1px 1px #0000001a;
}

.page-no .active {
  background-color: #ff7f03;
  color: #fff;
}

/*Pagination End */

/* setting tab */
.add-template {
  width: 120px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px;
}

.flex-column {
  background-color: #f8f8f8;
  height: 60vh;
  padding-top: 20px;
  overflow-x: hidden !important;
  width: 100%;

}

.tab-content {
  margin-top: -20px;
}

.nav-pills .nav-link {
  border-radius: 0rem !important;
  font-size: 13px;
  color: #000;
  font-family: "roboto";
  font-weight: 450;
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
}

.nav-pills .nav-link .one {
  position: relative !important;
  left: 125px !important;
  font-size: 10px;
}

.nav-pills .nav-link .two {
  position: relative !important;
  left: 95px !important;
  font-size: 10px;
}

.nav-pills .nav-link .three {
  position: relative !important;
  left: 140px !important;
  font-size: 10px;
}

.nav-pills .nav-link .four {
  position: relative !important;
  left: 73px !important;
  font-size: 10px;
}

.nav-pills .nav-link .five {
  position: relative !important;
  left: 123px !important;
  font-size: 10px;
}

.nav-pills .nav-link .six {
  position: relative !important;
  left: 10px !important;
  font-size: 10px;
}

.nav-pills .nav-link .seven {
  position: relative !important;
  left: 20px !important;
  font-size: 10px;
}

.nav-pills .nav-link .eight {
  position: relative !important;
  left: 100px !important;
  font-size: 10px;
}

.nav-pills .nav-link .nine {
  position: relative !important;
  left: 90px !important;
  font-size: 10px;
}

.nav-pills .nav-link .ten {
  position: relative !important;
  left: 100px !important;
  font-size: 10px;
}

.nav-pills .nav-link .ten-one {
  position: relative !important;
  left: 150px !important;
  font-size: 10px;
}

.nav-pills .nav-link .ten-two {
  position: relative !important;
  left: 75px !important;
  font-size: 10px;
}

.nav-pills .nav-link .ten-three {
  position: relative !important;
  left: 120px !important;
  font-size: 10px;
}

.new-report {
  margin-left: 140px;
  margin-top: 5px !important;
}

.nav-pills .nav-link .ten-zero {
  position: relative !important;
  left: 140px !important;
  font-size: 10px;
}

.nav-pills .nav-link .ten-new {
  position: relative !important;
  left: 30px !important;
  font-size: 10px;
}



.flex-column .ten-flex {
  position: relative !important;
  left: 147px !important;
  font-size: 10px !important;
}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ff7f03 !important;
  background-color: #fff !important;
  cursor: pointer;
}

.input-group-text {
  background-color: transparent !important;
  border: none !important;
  position: relative;
  right: 40px;
  z-index: 99;
  color: #aaaaaa !important;
}

.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.tab-content>.tab-pane {
  margin-top: 20px;
}

.setting {
  width: 100%;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0.4px;
}

.setting-class {
  margin-top: 20px;
}

.setting:hover {
  box-shadow: 0px 6px 6px #00000029;
}

.setting-span {
  position: absolute;
  right: 30px;
  font-size: 13px;
  top: 43px;
  color: #aaaaaa;
  z-index: 999;
}

.setting-spanss {
  position: absolute;
  right: 30px;
  font-size: 13px;
  top: 82%;
  color: #aaaaaa;
  z-index: 999;
}

.setting-spans {
  position: absolute;
  right: 30px;
  font-size: 13px;
  top: 65.5%;
  color: #aaaaaa;
  z-index: 999;
}

.setting-form .form-control {
  height: 40px;
}

.modal-unit-popup .form-control {
  height: 40px;
}

.setting-form label {
  font-size: 13.5px;
  font-weight: 500;
  font-family: "Roboto" !important;
}

.setting-form .require {
  position: relative;
  line-height: 0px;
}


.setting-form h1 {
  font-size: 14px;
}

.tab-pane .form-label {
  margin-bottom: 10px;
}

.unit-page h5 {
  font-size: 10px;
  position: absolute;
  left: 15px !important;
}

.Next {
  width: 300px;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 450;
  position: absolute;
  letter-spacing: 0.4px;
  bottom: 10px;
  right: 20px;
}

.qutation {
  bottom: -25px;
}

.tab-pane .form-control:focus {
  border: 2px solid #ff7f03 !important;
}

.form-select option {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 400;
}

.form-select {
  font-size: 13px !important;
  height: 39px !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.save-page {
  width: 100px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  padding: 6px;
  position: absolute;
  right: 20px;
  top: -35px;
}

.purchase-order {
  padding-left: 15px;
  padding-right: 25px;
}

.purchase_order_new {
  padding-left: 10px;
  padding-right: 15px;
}

.purchase_footer {
  padding-left: 20px;
  padding-right: 20px;
}

.save-page img {
  width: 20px;
  padding-right: 6px;
}

.preview-page {
  width: 100px;
  border: none;
  background-color: #18d6dc;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  padding: 6px;
  position: absolute;
  right: 140px;
  top: -35px;
}

.preview-page img {
  width: 20px;
  padding-right: 6px;
}

.save-pages {
  width: 100px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 8px;
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.save-pages img {
  width: 20px;
  padding-right: 6px;
}

.preview-pages {
  width: 120px;
  border: none;
  background-color: #18d6dc;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 8px;
  position: absolute;
  right: 140px;
  bottom: 10px;
}

.preview-pages img {
  width: 20px;
  padding-right: 6px;
}

.fade {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
}

.modal-content {
  border: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px !important;
}

/* setting tab */

.select-control {
  width: 200px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.filter-background .filter-button {
  position: absolute;
  top: 2%;
  right: 1.5%;
  background-color: #fff;
  border: 2px solid #ff7f03;
  color: #ff7f03;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 550;
  border-radius: 50px;
}

.filter-background .executive {
  position: absolute;
  top: 3%;
  right: 5%;
}

/* required field */
.require {
  font-size: 11px;
  color: #ff7f03;
  position: absolute;
  padding-top: 1px;
}

.require_pw {
  font-size: 11px;
  color: #ff7f03;
  position: relative;
}

.require-field {
  font-size: 11px;
  color: #ff7f03;
  position: absolute;
  top: 65% !important;
}

.require-sd {
  position: absolute;
  font-size: 11px;
  color: #ff7f03;
  top: 10px;
  left: 30px;
}

/* required field */

/* global search */
.customer .form-control {
  margin-bottom: 5px;
}

.staff-page .global {
  position: absolute;
  border-radius: 50px;
  right: 50%;
  top: 3.5%;
  padding-bottom: 6px;
  padding-top: 5px;
  padding-left: 33px;
  height: 31px !important;
  background-color: #f7f7f9 !important;
  border: none !important;
  font-size: 12px;
}

.staff-page .global:focus {
  border: 1px solid #ff7f03 !important;
}

.search span img {
  position: absolute;
  right: 27.5%;
  width: 13px;
  top: 5.1%;
  z-index: 99;
}

header {
  background-color: transparent !important;
  position: absolute !important;
  z-index: 0 !important;
  top: 17px;
  right: 4%;
}

.search-customer .global {
  position: absolute;
  right: 17.5%;
}

.search-customer span img {
  position: absolute;
  right: 32%;
  width: 13px;
  z-index: 99;
}

.product-search .global {
  position: absolute;
  right: 23%;
}

.product-search span img {
  position: absolute;
  right: 37.5%;
  width: 13px;
  top: 5.1%;
  z-index: 99;
}

.round {
  width: 20px !important;
}

/* global search */

/* Image upload start */
input::-webkit-file-upload-button {
  position: absolute;
  padding: 8px 45px;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  font-size: 11px;
  color: transparent;
  border: 1.7px solid #ff7f03;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-out;
  cursor: pointer;
  margin-left: -35px;
  margin-top: -28px;
}

input::-webkit-file-upload-button:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.upload {
  position: relative;
  top: 3.4%;
  z-index: 2;
  left: 0;
  font-size: 13px;
  color: #ff7f03;

}

.customer-dropdown {
  width: 190%;
  position: absolute;
  top: 5rem;
  z-index: 99;
  border: 2px solid #ced4da;
  border-radius: 5px;
  background-color: white;
  padding-left: 10px;
  height: 100px;
  overflow: auto;
}

.customer-dropdown p {
  margin: 5px;
  font-weight: 430;
  font-size: 15px;
}

.upload p {
  margin-left: -25px;
  position: relative;
  top: 20%;
}

.profile input[type="file"] {
  display: none;
}

.bulk_text {
  margin-top: 10px;
  color: #aaaaaa;
  text-decoration: underline;
  font-weight: 400;
  white-space: nowrap;
}

.bulk_btn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #000;
  font-size: 12px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 10px 25px;
  margin: 10px 17px;
  border-radius: 5px;
}

.bulk_btn img {
  width: 15px;
  margin-right: 5px;
  padding-bottom: 2px;
}

/* Image upload End */

/* pagenation style */
.sub-modal-btn {
  margin-top: 20px;
}

.count-one {
  float: left;
}

.count-two {
  float: right;
  text-align: right;
}

.page-link {
  margin-left: 10px !important;
  border-radius: 50px;
  color: #888 !important;
  font-weight: 590;
  box-shadow: 0px 1px 1px #0000001a;
  border: none !important;
}

.page-link:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #ff7f03 !important;
  border: none !important;
}

.page-page {
  font-size: 13px;
  padding-top: 20px;
  color: #888 !important;
}

.previous a {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.next a {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* pagenation style */

.rdt_TableHeadRow {
  background-color: #f8f8f8 !important;
  border-bottom-style: hidden !important;
  min-height: 35px !important;
  color: #aaaaaa !important;
}

.rdt_TableRow {
  border-bottom-style: hidden !important;
  color: #aaaaaa !important;
}

.pos-rel {
  position: relative;
}

.my-ul {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}

.my-ul>li {
  position: relative;
  display: block;
  height: 42px;
  line-height: 42px;
  font-size: 13px;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: "roboto";
  font-weight: 450;
}

.my-ul>li.active {
  color: #ff7f03;
}

.my-ul>li>span {
  position: absolute;
  right: 10px;
  top: 0px;
}

.my-tab-contents {
  display: none;
  transition: all 0.3s ease-in-out;
}

.new-popup {
  width: 95% !important;
}

.my-tab-contents.active {
  display: block;
}

.editor-line {
  width: 100%;
  height: 240px;
  border: 1.6px solid #c4c4c480 !important;
  border-radius: 3px;
}

.editor-line .ArchEditor-container {
  padding: 0px 10px;
}

.call-now {
  position: absolute;
  font-size: 12.5px;
  top: 49%;
  color: #aaaaaa;
  margin-left: 11px;
}

.fields {
  padding-top: 2.1px;
  margin-left: 11px;
}

.fie {
  padding-top: 3px;
  margin-left: 11px;
}

.call-field .form-control {
  padding-left: 33.5px;
}

.pro-imgs {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.float-name {
  position: absolute;
  font-size: 10px;
  top: 25%;
  color: #aaaaaa;
  left: 7%;
}

.space {
  background-color: #fff;
  width: 70px;
  height: 2px;
  top: 35%;
  left: 6%;
  position: absolute;
}

.product-one .global {
  margin-right: 15px !important;
}

.product-one span img {
  margin-right: 15px !important;
}

.filter-one {
  margin-right: 10px !important;
}

.customer-one .global {
  margin-right: 130px;
}

.customer-one span img {
  margin-right: 130px;
}

.customer-one {
  margin-right: 35px;
}

.new-ss {
  margin-top: 10px;
  padding-bottom: 10px;
}

.form-check-input {
  color: red !important;
}

.rdt_TableHeadRow {
  white-space: normal !important;
}

.new-back {
  margin-left: 25px;
  font-weight: 500 !important;
  cursor: pointer;
}

.fog-edit {
  margin-left: -10px !important;
  margin-top: 5px;
  width: 30px;
  cursor: pointer;
}

.fog-edits {
  width: 30px;
}

.loc_actions {
  margin-left: -10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.name-danger {
  text-align: center;
  font-size: 16px;
  margin-top: 100px;
  text-decoration: underline;
  color: #ff7f03;
}

.new-back img {
  width: 12px;
  margin-right: 7px;
  cursor: pointer;
}

.lnndaO {
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: clip !important;
  min-width: 100px;
}

.bsgpsK div:first-child {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;

}

.new-style {
  position: absolute;
  width: 121%;
  left: -20.5%;
  top: 0%;
  background-color: #fff;
  min-height: 470px;
  border-radius: 5px;
}

.fSQziN {
  margin-left: 40px;
  margin-right: 40px;
}

.okay {
  background-color: #EC2828;
  padding: 5px 45px;
  font-size: 15px;
  color: #fff;
  border-radius: 3px;
  border: none;
}

.no_btn {
  background-color: #AAAAAA7F;
  padding: 5px 45px;
  font-size: 15px;
  color: #000;
  border-radius: 3px;
  border: none;
  margin: 0 10px 30px 10px;
}

.no {
  background-color: #AAAAAA7F;
  padding: 5px 45px;
  font-size: 15px;
  color: #000;
  border-radius: 3px;
  border: none;
  margin: 0 10px 30px 10px;
}

.new-btns {
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px 20px;
  position: absolute;
  white-space: nowrap;
  right: 40px;
  top: -10px;
  z-index: 99;
}

.new-btns {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #bc5c00;
}

.new-btns:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.new-btns img {
  width: 14.3px;
  margin: 0 3px 2px;
}

.product-edit {
  width: 20px !important;
  margin-top: -10px !important;
  cursor: pointer;
}

.next-btn button {
  float: right;
  margin-right: 20px;
  background-color: #F8F8F8;
  border: none;
  padding: 3px 20px;
  border-radius: 20px;
  font-size: 13px !important;
}

.prev-btn button {
  float: left;
  margin-left: 20px;
  background-color: #F8F8F8;
  border: none;
  padding: 3px 20px;
  border-radius: 20px;
  font-size: 13px !important;
}

.nav-link {
  text-decoration: none !important;
}

.page-link {
  text-decoration: none !important;
}

.prev-btn button img {
  width: 15px;
  margin: 0 8px 3px;
}

.next-btn button img {
  width: 13px;
  margin: 0 8px 2px;
}

.btn-button {
  margin-top: 30px;
}

.sc-hKwDye {
  border: 1px solid #e4e2e2;
}

.sc-dlVxhl {
  margin: 0 40px;
  border: 1px solid #e4e2e2;
}

.jbTKDn {
  margin-right: 10px;
}

.product_header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product_header img {
  margin: -5px 5px 0;
}

.new-back img {
  margin-top: 0.2px;
}

iframe {
  border: none;
  padding: 5px;
}


.colorss span {
  color: orange;
}

.colorss {
  font-size: 13px !important;
  font-weight: 500;
}

.new-colo .form-control {
  display: inline;
  margin-top: 5px;

}

.staff-page .table-class .table tbody tr .new-sizes {
  white-space: nowrap;
  max-width: 500px !important;
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-overflow: ellipsis !important; */
}

.table-class .table tbody tr .new_bulk_size {
  white-space: nowrap;
  max-width: 150px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-overflow: ellipsis !important; */
}

.table-class .table tbody tr .new_bulk_sizee {
  white-space: nowrap;
  max-width: 150px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-overflow: ellipsis !important; */
}

.new_bulk_sizee {
  width: 30%;
}


.table-class .table tbody tr .new_product_size {
  white-space: nowrap;
  max-width: 400px !important;
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-class .table tbody tr .inward_new_size {
  white-space: nowrap;
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.table-class .table tbody tr .inward_project_size {
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.table-class .table tbody tr .customer_name_size {
  white-space: nowrap;
  max-width: 250px;
  width: 23%;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.inward_new_size_new {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.table-class .table tbody tr .dashboard_table_item {
  white-space: nowrap;
  max-width: 240px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.staff-page .table-class .table tbody tr .new_price_td {
  white-space: nowrap;
  min-width: 60px !important;
  text-align: right;
}

.inward_new_size {
  width: 15rem;
  text-overflow: ellipsis !important;
}


.inward_project_size {
  width: 13%;
  text-overflow: ellipsis !important;
}

.customer_name_size {
  width: 15%;
}

.new-sizes {
  width: 45%;
}

.dashboard_table_item {
  width: 22%;
}

.new_product_size {
  width: 35%;

}

.privacy {
  font-size: 11px;
  position: relative;
  top: 14%;
  left: 30%;
  color: #888;
}

.privacy:hover {
  color: #bc5c00;
}

.cut {
  font-size: 11px;
  position: relative;
  top: 14%;
  left: 31.5%;
  color: #888;
}

.terms-condition {
  font-size: 11px;
  position: relative;
  top: 14%;
  left: 33%;
  color: #888;
}

.terms-condition:hover {
  color: #bc5c00;
}

.arg i {
  position: relative;
  margin-left: -40px !important;
  padding-right: 20px;
  font-size: 20px;
  color: #000;
}

.rdt_TableHeader {
  width: 10px;
}


.table-classs .table tr th {
  border: 1.5px solid #cac9c9;
  max-height: 20px;
}

.table-classs .table tr td {
  border: 1.8px solid #cac9c9;
}

.float-nam {
  font-size: 13px;
}

.float-names {
  font-size: 13px;
}

.float-namess {
  font-size: 13px;
}

.float-namesss {
  font-size: 13px;
}

/* .new-pro .form-control {
  padding-top: 20px;
} */

.new-pro .form-control {
  margin: 0px !important;
}

.new-pro label {
  font-size: 13.5px;
  margin-top: 7px;
  margin-bottom: 5px;
  font-weight: 500;
}

.border-area {
  border: 1.6px solid hsla(0, 0%, 76.9%, .5019607843137255) !important;
  padding: 7px;
  font-size: 13.5px;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: not-allowed ! important;
  margin-bottom: 0px;
}

.new-gg {
  display: flex;
  float: left;
  align-items: center;
  position: absolute;

}

.new-gg img {
  margin-left: 6px !important;
}

.go-hr {
  width: 93%;
  height: 3px;
  top: 1490px;
  left: 20px;
  right: 20px;
  position: relative;
  background-color: red;
}

.rt-img {
  position: relative;
  left: 450px;
  text-align: center;
  margin-top: 20px;
}

.rt-imgss {
  position: relative;
  left: 480px;
  text-align: center;
}

.rbt-close {
  position: absolute;
  top: 1188px;
  left: 7px;
  background-color: red !important;
  border-radius: 50%;
  width: 23px;
  height: 23px;
}

.load-gg {
  position: relative;
  left: 570px;
  margin-top: 50px;
  width: 80px;
}

.load-gs {
  position: relative;
  left: 560px;
  font-size: 25px;
  margin-top: 20px;
}

.forward {
  width: 12px !important;
  margin-left: 20px;
}

.nexts {
  width: 12px !important;
  margin-right: 20px;
}


.page-not-found {
  text-align: center;
  background-color: #E5F9FF;
  height: 100vh;
}

.page-not-found img {
  margin-top: 80px;
}

.page-not-found h1 {
  font-weight: 900;
  font-size: 45px;
}

.page-not-found p {
  font-size: 18px;
  font-weight: 600;
}


.login-button {
  background-color: #59BDDE;
  padding: 6px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.select-drop {
  padding: 25px 25px 0px;
}


















/* second phase */



.page-head-split div {
  display: flex;
  align-items: center;
}

.page-head-split .head-colums {
  width: 100%;
}

.page-head-split .head-colums .head-part-tab {
  width: 25%;
}

.page-head-split div .tab-links {
  width: 40%;
  white-space: nowrap;
}

.page-head-split .head-colums .customer-search,
.page-head-split .head-colums .package-search {
  width: 22% !important;
}

.page-head-split .head-colums .oa-search {
  margin-top: 3px;
  width: 26% !important;
}

.page-head-split .head-colums .add-staff {
  width: 10% !important;
}

.page-head-split .head-colums h6 {
  font-size: 17px;
  margin-left: 15px;
  margin-top: 8px;
}

.page-head-split div .tab-links a {
  margin-left: 30px;
  color: #aaaaaa;
}

.main-search {
  border-radius: 30px !important;
  height: 35px !important;
  padding-left: 30px !important;
}

.main-searchh {
  border-radius: 30px !important;
  height: 35px !important;
  padding-left: 30px !important;
  width: 100px;
}

.customer-search .search-img {
  position: relative;
  left: 25px;
  width: 14px;
}

.package-search .search-img {
  position: relative;
  left: 25px;
  width: 14px;
}

.page-head-split div .tab-links .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}


.page-head-split div .tab-links-staff {
  width: 30%;
}

.page-head-split div .tab-links-staff a {
  margin-left: 30px;
  color: #aaaaaa;
}

.page-head-split div .tab-links-staff .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}

/* repeat width card header */

.page-head-split .head-colums .head-part-tabs {
  /* width: 48%; */
  width: 57%;
}

.page-head-split .head-colums .head-part-tabs-one {
  width: 78%;
}

.page-head-split div .tab-linkk {
  width: 20%;
}

.page-head-split div .tab-linkk a {
  margin-left: 30px;
  color: #aaaaaa;
}

.page-head-split div .tab-linkk .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}


.page-head-split div .tab-linke {
  width: 25%;
}

.page-head-split div .tab-linke a {
  margin-left: 30px;
  color: #aaaaaa;
}

.page-head-split div .tab-linke .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}

.page-head-split div .tab-linkd {
  width: 35%;
}

.page-head-split div .tab-linkd a {
  margin-left: 30px;
  color: #aaaaaa;
}

.page-head-split div .tab-linkd .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}


.page-head-split div .tab-linki {
  width: 30%;
}

.page-head-split div .tab-linki a {
  margin-left: 30px;
  color: #aaaaaa;
}

.page-head-split div .tab-linki .active {
  color: #ff7f03;
  border-bottom: 3px solid #ff7f03;
}

/* repeat width card header End */

/* tooltip start */

.change-pro-btn {
  margin-top: 12px;
  border: none;
  background-color: #ff7f03;
  color: #FFF;
  font-size: 0.8rem;
  padding: 0.6rem 1rem;
  border-radius: 4px;
}

[tooltip] {
  position: relative;
}

[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: .9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em + 5px);
  transform: translate(.5em, -50%);
}

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% - 5px);
  transform: translate(.5em, -50%);
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

/* tooltip End */

.product-filter-button {
  background-color: #fff;
  border: 2px solid #ff7f03;
  color: #ff7f03;
  margin-left: 22px;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 550;
  border-radius: 50px;
}

.add-stock {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #000;
  font-size: 12px;
  margin-left: 22px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 7px 17px;
}

.add-stock img {
  width: 20px;
  padding-right: 5px;
}

.add-stock:active {
  background-color: #686666;
  box-shadow: 0 2px #939292;
  transform: translateY(2px);
}

.product-add-staffs {
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px 20px;
  margin-left: 22px;
  white-space: nowrap !important;
  box-shadow: 0 2px #bc5c00;
}

.product-add-staffs:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.product-add-staffs img {
  width: 20px;
  padding-right: 6px;
}

button {
  white-space: nowrap;
}


.dropbtn {
  background-color: transparent;
  color: white;
  z-index: 999 !important;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  width: 200px;
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
}

.dropdown-content p {
  color: black;
  cursor: pointer;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content p:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

.dropdown-content a img {
  width: 15px;
  margin-top: 3px;
  margin-right: 10px;
}

.dropdown-content a {
  font-size: 13px;
}

.dropdown-content p img {
  width: 20px;
  margin-top: 3px;
  margin-right: 10px;
  filter: invert(55%) sepia(93%) saturate(2487%) hue-rotate(358deg) brightness(101%) contrast(104%);

}

.dropdown-content p {
  font-size: 13px;
}






/* sidebar color css */


.side-bar .sky-blue-active li {
  background-color: #0ABCFF !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .sky-blue-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .sky-blue-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .green-active li {
  background-color: #24BC84 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .green-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .green-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .red-active li {
  background-color: #C84163 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .red-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .red-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .yellow-active li {
  background-color: #FFCD03 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .yellow-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .yellow-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .ink-blue-active li {
  background-color: #177DE2 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .ink-blue-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .ink-blue-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .greens-active li {
  background-color: #000 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .greens-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .greens-active img {
  filter: brightness(0) invert(1) !important;
}


.side-bar .gr-active li {
  background-color: rgb(64, 210, 31) !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .gr-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .gr-active img {
  filter: brightness(0) invert(1) !important;
}

.side-bar .pinky-active li {
  background-color: #e15098 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .pinky-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .pinky-active img {
  /* filter: invert(50%) sepia(94%) saturate(2042%) hue-rotate(300deg) brightness(90%) contrast(95%); */
  filter: brightness(0) invert(1) !important;
}

.pinky li img {
  filter: invert(50%) sepia(94%) saturate(2042%) hue-rotate(300deg) brightness(90%) contrast(95%);
}

.adds-email {
  text-decoration: underline;
  color: rgb(3, 168, 3) !important;
  cursor: pointer;
}

.adds-email img {
  width: 17px;
  margin-right: 4px;
}

.side-bar .violet-active li {
  background-color: #BB4AF2 !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .violet-active li:hover {
  color: #fff !important;
  width: 50px;
}

.side-bar .violet-active img {
  filter: brightness(0) invert(1) !important;
}

/* sidebar color css end*/



.dashboard-tag a:hover {
  color: #121212;
}

.create-del {
  margin-left: 20px;
  border: none;
  background-color: #0ABCFF;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 7px 20px;
  box-shadow: 0 2px #107ca3;
}

.create-del:active {
  background-color: #107ca3;
  box-shadow: 0 2px #107ca3;
  transform: translateY(2px);
}

.create-del img {
  width: 20px;
  padding-right: 6px;
}

.create-po {
  margin-left: 20px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  margin-top: -5px;
  padding: 6px 20px;
}

.create-po {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #bc5c00;
}

.create-po:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.create-po img {
  width: 20px;
  padding-right: 6px;
}

.convert-po {
  margin-left: 20px;
  border: none;
  background-color: #C84163;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  margin-top: -5px;
  padding: 6px 20px;
}

.convert-po {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #722337;
}

.convert-po:active {
  background-color: #722337;
  box-shadow: 0 2px #722337;
  transform: translateY(2px);
}

.convert-po img {
  width: 20px;
  padding-right: 6px;
}

.order-page-title {
  margin: 20px 20px 20px 10px;
  /* margin-left: 0px; */
  /* margin-left: 30px; */
}

.order-page-title a {
  margin-left: 20px;
  padding-bottom: 3px;
  color: #acaaaa;
  font-size: 15px;
}

.order-page-title a:hover {
  margin-left: 20px;
  color: #acaaaa;
}

.order-page-title .active {
  color: #ff7f03;
  border-bottom: 2px solid #ff7f03;
}

.order-page-title .active:hover {
  color: #ff7f03;
  border-bottom: 2px solid #ff7f03;
}

.line-new {
  position: absolute;
  width: 93.5%;
  top: 80px;
  left: 3.5%;
  height: 5px !important;
}

.vendor-details {
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 170px;
}

.vendor-detail {
  padding-left: 30px;
}

.new-vendor {
  margin-top: 30px;
  background-color: #ff7f03;
  padding: 9px 80px;
  border: none;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}

.new-vendor img {
  margin-right: 10px;
  width: 15px;
}

.view-button {
  border: none;
  padding: 9px 20px;
  padding-right: 25px;
  margin-top: 30px;
  font-size: 13px;
  background-color: #D9EAFB;
  color: #177DE2;
  border-radius: 3px !important;
  margin-left: -10px;
}

.view-button i {
  font-size: 17px !important;
  position: relative;
  margin-left: 68px;
  top: 30%;
  color: #000;
}

.page-handle {
  display: flex;
  align-items: baseline;
}

.add-product-details {
  border: none;
  padding: 9px 20px;
  padding-right: 25px;
  margin-top: 30px;
  margin-left: 20px;
  font-size: 13px;
  background-color: #17C93B29;
  color: #17C93B;
  border-radius: 3px !important;
}

.table-blue thead tr th {
  background-color: #177DE2 !important;
  color: #fff;
  font-weight: 400 !important;
  position: sticky;
  top: 0;
}

.table-blue tbody {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100px !important;
}

.table-back {
  background-color: #D9EAFB !important;
  padding: 20px 5px 5px;
  width: 103%;
  margin-top: 15px !important;
  /* margin-left: 10px; */
}

.package-products-view {
  width: 95%;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-bottom: 25px;
  }
  
  .pack-pos-center {
    text-align: center;
  }


.delete-purchase {
  margin-left: 40px;
  margin-top: 30px;
  width: 35px;
  cursor: pointer;
}

.span-name {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.top-space {
  margin-top: 10px;
}

.new-tic {
  position: relative;
  top: 5px;
}

.new-link-staff tr td a {
  color: #000;
}

.new-link-staff tr td a:hover {
  color: #ff7f03;
}

.new-vendor {
  position: absolute;
  left: 30px;
  top: 10px;
  z-index: 99;
}

.new-from {
  margin-left: 140px !important;
}


/*Purchase Order*/
.ul-h {
  display: flex;
  align-items: flex-end;
}

.ul-h>li {
  display: flex;
}

.po-rows {
  background-color: #eee;
  min-height: 300px;
}

.empty-data-set {
  margin-top: 10px;
  font-size: 15px;
  position: relative;
  left: 300px;
  top: 10px;
}

.under-line h5 {
  margin-top: -10px !important;
  text-decoration: underline;
}

.under-line h6 {
  margin-top: -10px !important;
  text-decoration: underline;
}

.under_line_para {
  text-decoration: underline;
}

.search-sale {
  width: 250px;
  position: absolute;
  right: 190px;
}

.search-sale img {
  position: absolute;
  right: 223px;
  width: 14px;
  top: 10px;
}

.exe_icon {
  margin-top: -10px;
}

/*Purchase Order*/


.cross {
  position: relative;
  right: 5%;
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.convert_po_input {
  width: 70px;
  text-align: center;
  border: 1px solid #AAAAAA80;
  background-color: #FFFFFF;
  border-radius: 4px;
  /* padding: 4px 20px; */
}

.big-input {
  text-align: center;
  border: 1px solid #AAAAAA80;
  background-color: #FFFFFF;
  border-radius: 4px;
}


tr td span p {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.sent_btn {
  background-color: #B01A40;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer;
}

.co_span {
  position: relative;
  top: 0px;
  left: 5px;
  border: 1.6px solid hsla(0, 0%, 76.9%, .5019607843137255) !important;
  padding: 3px 10px 13px;
  font-size: 13.5px;
  color: grey;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: not-allowed ! important;
}

.css-1s2u09g-control {
  border: 1.6px solid hsla(0, 0%, 76.9%, .5019607843137255) !important;
  padding: 1px;
  font-size: 13px;
}

.add-padd {
  padding-left: 30px;
  padding-right: 35px;
}

.add-padd-top {
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 35px;
  padding-bottom: 5px;
}

.pack-add-bottom {
  margin-bottom: 20px;
}
.co_span span {
  font-size: 12px;
  position: relative;
  top: -3px;
  white-space: nowrap;
}

.co_span p {
  position: absolute;
  top: 18px;
  margin: 0;
}

.download-po-btn {
  position: absolute;
  top: 1.5rem;
  right: 8.3rem;
}

.product-download-btn {
  position: relative;
  top: 0;
  right: 0;
}

.download_btn img {
  width: 17px;
  margin-right: 10px;
  filter: invert(100%) sepia(0%) saturate(4307%) hue-rotate(109deg) brightness(107%) contrast(101%);
}

.download_btn:active {
  background-color: red;
}

.download_btn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #871596;
  font-size: 12px;
  margin-left: 22px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 7px 17px;
}

.download_btn_pdf {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: red;
  font-size: 12px;
  margin-left: 22px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 7px 17px;
}

.download_btn_pdf img {
  width: 17px;
  margin-right: 10px;
  filter: invert(100%) sepia(0%) saturate(4307%) hue-rotate(109deg) brightness(107%) contrast(101%);
}

.triangle-status {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid black;
  position: absolute;
  top: 26px;
  right: 57px;
}


.triangle-bottom {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid black;
  position: absolute;
  top: 40px;
  right: 30px;
}

.triangle-role {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid black;
  position: absolute;
  top: 16px;
  right: 30px;
}

.form-select,
.select-control {
  -webkit-appearance: none !important;
}

.form-control-placeholder {
  position: absolute;
  top: 14px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 500;
}

.form-control-label {
  position: absolute;
  top: -10px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 500;
}

.form-control-labelk {
  position: absolute;
  top: 15px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 500;
}

.form-control-placeholder span,
.form-control-label span,
.form-control-labelk span {
  color: red;
  font-weight: 900;
}

.form-control-placeholders {
  position: absolute;
  top: -16px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 500;
}

.form-control-placeholders span {
  color: red;
  font-weight: 900;
}

.txt-center {
  text-align: center;
}

.center_align {
  padding-left: 30px;
}

.txt-right {
  text-align: right;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
  color: #aaaaaa;
}

.type-form {
  margin-top: -25px;
  z-index: 99;
}

.type-forms {
  margin-top: -22px;
  z-index: 99;
}

.hide_td {
  z-index: -1;
}

.check-item {
  z-index: 99 !important;
}

.blur {
  backdrop-filter: blur(0px);
}




.orange-head {
  background-color: #ff7f03;
  color: #fff;
}

.orange-head .h4 {
  font-size: 15px !important;
}

.email-body .modal-content {
  position: absolute;
  top: 240px;
  left: 75%;
}

.sent-button {
  background-color: #ff7f03;
  color: #fff;
  font-size: 14px;
  border: 1px solid #ff7f03;
  padding: 5px 15px;
  border-radius: 2px;
}

.email-body .modal-content .dis-part {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid #d4d3d3 !important;
  font-size: 13px;
  width: 100%;
}

.email-body .modal-content span {
  color: #aaaaaa;
  font-size: 13px;
  position: absolute;
  margin-top: 23px;
  margin-left: 10px;
}

.email-body input[type=text] {
  border: none;
  background-color: none;
  outline: 0;
}

.email-body input[type=text]:focus {
  border: none;
  background-color: none;
  outline: 0;
}

.mail-border .dis-part {
  padding-left: 35px;
  padding-bottom: 10px;
}

.sub-border .dis-part {
  padding-left: 65px;
  padding-bottom: 10px;

}

.select-border .css-1s2u09g-control {
  border: none !important;
  border-bottom: 2px solid #d4d3d3 !important;
  border-radius: 0px !important;
}

.select-border .css-6j8wv5-Input {
  width: 20px;
}

#react-select-2-input {
  margin-left: 25px !important;
}

.select-border {
  margin-top: 20px;
}

.mi {
  width: 35px;
  margin-top: -2px;
  margin-right: 5px;
}

.select-border span {
  position: absolute;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px !important;
  color: #aaaaaa;
  z-index: 99;
}

.select-border .css-14el2xx-placeholder {
  display: none;
}

.select-border .css-g1d714-ValueContainer {
  padding-left: 40px !important;
}


.email-body .modal-content input[type=textarea] {
  height: 200px;
}

.email-body ::placeholder {
  font-size: 13px !important;
  padding-left: -10px !important;
}

.email-body .modal-footer {
  padding: 6px !important;
}

.email-body .sub {
  position: absolute;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 10px;
  color: #aaaaaa;
}


.email-body .inside-sub {
  padding-left: 65px;
}

.sent-button .roc {
  width: 18px;
  margin-left: 5px;
  margin-top: -5px;
}

/* quotation new */

.orange-trigger {
  border-radius: 5px;
  width: 100% !important;
  margin-left: 0px !important;
  cursor: pointer;
}

.total_descrip {
  display: flex;
}

.orange-trigger .row {
  transition: all 1s linear;
}

.group_new_body {
  margin: 20px 0 0 0;
  width: 101%;
}

.orange-triggers {
  margin-top: -10px;
  background-color: #ff7f03;
  border-radius: 5px;
  width: 70% !important;
  margin-left: 0px !important;
  /* margin-right: 10px !important; */
  cursor: pointer;
  transition: 100ms ease-in-out;
}

.orange-triggers img {
  margin-top: -3px;
  margin-left: 10px;
  width: 12px;
  /* filter: brightness(0) invert(1); */
  transition: 100ms ease-in-out;
}

.orange-trigger h1 {
  margin-right: 10px;
  font-size: 13px !important;
  margin-top: 10px;
  color: #fff;
}

.added_content {
  display: flex;
  align-items: center;
}

.footer-round {
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 10px;

}

.footer-rounds {
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 20px;

}

.mini-popup {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 89%;
  bottom: 30px;
  left: 120px;
  right: 200px;
  z-index: 999;
  position: fixed !important;
  border-radius: 10px;
}

.product_pop {
  background-color: #fff;
}

.custom-body {
  /* background-color: green; */
  padding: 30px;
  border-radius: 0px 0px 10px 10px;
}

.modal-staff-here {
  padding: 20px 20px 35px;
}

.sub-side .side {
  display: flex;
  align-items: baseline;
}

.sub-side h4 {
  font-size: 13px;
}

.orange-separtae-row {
  display: flex;
}

.orange-separtae-row h1 {
  padding-left: 50px;
  white-space: nowrap;
}

.trig-but {
  margin-left: 10px;
}

.add_product_new {
  margin-top: 20px;
  background-color: #F8F8F8;
  display: flex;
  justify-content: space-around;
}

.add_product_new div {
  padding: 3px 0 0;
}

.add_product_new div p {
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 400;
}

.add_product_new div h3 {
  margin: -10px 0 2px;
  padding: 1px 0;
  font-size: 12.5px;
  color: #000;
  font-weight: 450;
}

.new_add_product {
  width: 100%;
  margin-top: -5px;
  /* background-color: #17C93B29; */
  /* border: none; */
  display: flex;
  justify-content: space-between;
  /* padding: 5px 0px; */
  /* padding:  */
  /* min-width: 60%; */
  /* border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms; */
}

.new_add_product p:first-child {
  color: #17C93B;
  margin: 6px 20px;
}

.new_add_product p:not(:first-child) {
  color: #17C93B;
  margin: 6px 20px;
}

/* .rbt-menu .dropdown-menu .show */

/* quotation new */

.new-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
}

.mini-popup-form {
  padding: 20px !important;
}

.mini-popup-form .ant-form-item {
  margin-bottom: 0px !important
}

.mini-popup-form .mini-form-list .ant-form-item {
  margin-bottom: 20px !important
}

.size_logo {
  width: 100px;
  height: 70px;
}

.ant-upload-list-item-info {
  display: none;
}

.mini-popup-form label {
  position: relative;
  top: 16px;
  margin-left: 10px;
  padding-left: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 500;
  z-index: 99;
}

.oa-search .ant-input {
  border: 1.6px solid #c4c4c480 !important;
  font-size: 13.5px !important;
  height: 40px !important;
  margin: -80px 0px !important;
}

.oa-search .ant-input:focus {
  border: 1.6px solid #ff7f03 !important;
  box-shadow: none !important;
}

.mini-form-list {
  margin-top: 20px;
}

.ant-cust-btn button {
  margin-top: 10px;
}

.ant-cust-btn .ant-btn-primary {
  background-color: #ff7f03 !important;
  border: none !important;
}

.ant-upload.ant-upload-drag {
  height: 70px !important;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0px !important
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff7f03 !important;
  border-color: #ff7f03 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #ff7f03 !important;
}

.ant-checkbox+span {
  font-weight: 401 !important;
  font-size: 14px !important;
  color: #aaaaaa !important;
}

.template-download-tab p {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  width: 80px;
  color: grey;
  margin-top: 10px;
}

.td-class p {
  margin: 5px 5px 5px 0px !important;
}

.d-inline-block .MsoNormal {
  display: none;
}

.uniq-table {
  width: 100%;
  border-collapse: collapse;
}

.uniq-table thead tr th,
.uniq-table tbody tr td,
.uniq-table tbody tr td .value-add {
  max-width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.uniq-table tbody tr td.cell-txt-center {
  padding-left: 35px;
}

.uniq-table tbody tr .end-child {
  min-width: 130px !important;
  text-align: center;
}

.uniq-table tbody tr .product_name {
  min-width: 350px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}


.side-bar .package-active li {
  background-color: #EADDCA !important;
  color: #fff !important;
  padding: 10px;
  width: 50px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
  font-size: 13.5px;
  border: none;
}

.side-bar .package-active li:hover {
  color: #fff !important;
  width: 50px;
}

.table-blue-bg {
  padding: 0px 0px !important;
}
.table-blue-bg thead {
  background-color: #177DE2;
  color: #fff;
}

.table-blue-bg tbody tr td {
  border: 1px solid #d4cbcb;
  }
  
  .package-pop {
    padding: 30px;
  }
.pack-prod-pop {
  padding: 35px 30px 0px 30px;
}

.pack-prod-pop p {
  position: absolute;
  top: 35px;
  font-size: smaller;
  color: #ff7f03;
}
.package-pop h4 {
  font-size: 15px;
}

.Edit-Package {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: #ff7f03;
  font-weight: 450;
  text-decoration: underline;
}
.package-input {
    margin-top: 30px;
    margin-left: 5px;
    padding: 5px;
    width: 100%;
}

.product-label-form .ant-input {
  height: 30px !important;
}

.product-label-form .ant-input:focus {
  border: 1.6px solid #ff7f03 !important;
  box-shadow: none !important;
  height: 30px !important;
}

.product-label-form .ant-input:hover {
  border: 1.6px solid #ff7f03 !important;
  box-shadow: none !important;
  height: 30px !important;
}

.product-label-form .ant-input:focus {
  border: 1.6px solid #ff7f03 !important;
  box-shadow: none !important;
}

.floating-label-form .ant-input:hover {
  border: 1.6px solid #ff7f03 !important;
  box-shadow: none !important;
}

.package-button[type=button]:not(:disabled),
.package-button[type=reset]:not(:disabled),
.package-button[type=submit]:not(:disabled),
.package-button button:not(:disabled) {
  cursor: pointer;
  background-color: #ff7f03;
  height: 33px;
    color: white;
    width: 100%;
}
.table-back-pack {
  background-color: #D9EAFB !important;
  padding: 0px 0px 5px 0px !important;
  width: 95%;
  margin-top: -10px !important;
}
.product-form-package-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.product-form-package-check label {
  margin-bottom: 0px !important;
}

.ant-switch-checked {
  background-color: #ff7f03 !important;
}

.switch-package {
  display: flex;
  flex-direction: row;
}

.switch-package p {
  font-size: 13px;
  color: #AAAAAA;
  margin: 0px 5px 0px 5px;
}

.switch-package .select-ptag {
  color: #ff7f03;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.fr-quick-insert a.fr-floating-btn{
  display: none !important;
}
