.floating-label-container {
    position: relative;
    /* margin-bottom: 15px; */
}

.floating-label-container p {
    position: absolute;
    top: 43px;
    font-size: x-small;
    color: #ff7f03;
}
.floating-label {
    position: absolute;
    top: 0;
    left: 12px;
    pointer-events: none;
    transition: transform 0.2s ease-out, font-size 0.2s ease-out, color 0.2s ease-out;
}

.focused .floating-label,
.has-value .floating-label {
    transform: translateY(-100%) scale(0.75);
    font-size: 14px;
    color: #333;
}

.ant-input {
    background-color: transparent;
}

.floating-label-form button {
    width: 100%;
    margin-right: 20px;
}

.floating-label-form .ant-input {
    height: 40px;
}

.floating-label-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
}

.floating-label-form .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
    border-color: #ff7f03 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.floating-label-form .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 3px !important;
}
.add-product-pack-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.add_po_button,
.add_po_button:hover,
.add_po_button:focus {
    background-color: #17C93B;
        color: white;
        border-radius: 2px;
    height: 40px;
    border: none;
    width: 20% !important;
        margin-top: -8px;
        margin-bottom: 5px;
}

.add_po_button img {
    width: 13px;
    margin-right: 5px;
}

.view_po_button,
.view_po_button:hover,
.view_po_button:focus {
    background-color: #D9EAFB;
    color: #177DE2;
    height: 40px;
    border: none;
}

.sub-btn,
.sub-btn:hover,
.sub-btn:focus {
    background-color: #ff7f03;
    color: #fff;
    height: 40px;
    border: none;
}

.update-btn,
.update-btn:hover,
.update-btn:focus {
    background-color: rgba(255, 127, 3, 0.1);
    color: #fff;
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -6px;
    border: none;
}

.update-img {
    position: absolute;
    top: 8px;
    right: 10px;
}

.pack_update-btn,
.pack_update-btn:hover,
.pack_update-btn:focus {
    background-color: #ff7f03;
    color: #fff;
    height: 40px;
    border: none;
    margin: 0px 20px 20px 20px;
    width: 40% !important;
    align-items: center;
}
.table-blue-bg {
    padding: 40px 40px;
}
.input-form {
    position: relative;
}

.product-form {
    margin-bottom: 80px;
    background-color: rebeccapurple;
}

.form-control-placeholder-pack {
    position: absolute;
    top: -22px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    font-size: 12px;
    color: #aaaaaa;
    font-weight: 500;
}

.form-control-placeholder-pack span {
    color: red;
}